import React from 'react'
import { Container, Row, Col } from 'react-grid-system'
import { useIntl, FormattedMessage, Link } from 'gatsby-plugin-intl'

import Layout from '../../../components/Layouts/layout'
import SEO from '../../../components/seo'
import SubPageNav from '../../../components/SubPageNav/SubPageNav'
import SubPageBottomNav from '../../../components/SubPageBottomNav/SubPageBottomNav'
import ToolTip from '../../../components/ToolTip/ToolTip'

const BuilderBeforeApplyPage = () => {
  const intl = useIntl()
  return (
    <Layout
      view='builder'
      breadcrumb={{
        text: <FormattedMessage id='builderNav.dashboard' />,
        path: '/builder-vendor/dashboard/'
      }}
      header={
        <>
          <FormattedMessage id='builderNav.becomeA' />{' '}
          <span style={{ fontWeight: `bold` }}>
            <FormattedMessage id='builderNav.becomeB' />
          </span>
        </>
      }
    >
      <SEO
        lang={intl.locale}
        title={intl.formatMessage({ id: 'builderNav.step2' })}
      />
      <Container>
        <Row>
          <Col>
            <SubPageNav
              links={[
                {
                  text: `${intl.formatMessage({ id: 'builderNav.step1' })}?`,
                  path:
                    '/builder-vendor/become-a-licensed-builder/do-i-need-a-licence'
                },
                {
                  text: <FormattedMessage id='builderNav.step2' />,
                  path:
                    '/builder-vendor/become-a-licensed-builder/before-you-apply',
                  active: true
                },
                {
                  text: <FormattedMessage id='builderNav.step3' />,
                  path:
                    '/builder-vendor/become-a-licensed-builder/applying-for-a-licence'
                },
                {
                  text: <FormattedMessage id='builderNav.step4' />,
                  path:
                    '/builder-vendor/become-a-licensed-builder/after-your-application-is-submitted/'
                },
                {
                  text: <FormattedMessage id='builderNav.step5' />,
                  path:
                    '/builder-vendor/become-a-licensed-builder/after-licensing'
                }
              ]}
            />
          </Col>
        </Row>

        <Row>
          <Col>
            <h2>
              <FormattedMessage id='becomeBuilder2.headerA' />
            </h2>
            <p>
              <FormattedMessage id='becomeBuilder2.contentAi' />{' '}
              <a
                href={intl.formatMessage({ id: 'becomeBuilder2.linkA' })}
                aria-label={intl.formatMessage({
                  id: 'becomeBuilder2.contentAii'
                })}
              >
                <FormattedMessage id='becomeBuilder2.contentAii' />
              </a>
              . <FormattedMessage id='becomeBuilder2.compReqAi' />{' '}
              <Link to='/builder-vendor/competency-requirements/'>
                <FormattedMessage id='becomeBuilder2.compReqLink' />
              </Link>
              <FormattedMessage id='becomeBuilder2.compReqAii' />
            </p>
          </Col>
        </Row>

        <Row>
          <Col>
            <h2>
              <FormattedMessage id='becomeBuilder2.headerH' />
            </h2>
            <p>
              <FormattedMessage id='becomeBuilder2.contentHi' />{' '}
              <a
                href='https://pages.sterlingbackcheck.ca/landing-pages/h/hcra/'
                aria-label={intl.formatMessage({
                  id: 'becomeBuilder2.contentHii'
                })}
              >
                <FormattedMessage id='becomeBuilder2.contentHii' />
              </a>{' '}
              <FormattedMessage id='becomeBuilder2.contentHiii' />{' '}
              <b>
                <FormattedMessage id='becomeBuilder2.contentHvii' />
              </b>{' '}
              <FormattedMessage id='becomeBuilder2.contentHviii' />
            </p>
            <p>
              <FormattedMessage id='becomeBuilder2.contentHiv' />
            </p>
            <p>
              <FormattedMessage id='becomeBuilder2.contentHv' />
            </p>
            <p>
              <FormattedMessage id='becomeBuilder2.contentHvi' />
            </p>
          </Col>
        </Row>

        <Row>
          <Col>
            <h2>
              <FormattedMessage id='becomeBuilder2.headerI' />
            </h2>
            <p>
              <FormattedMessage id='becomeBuilder2.contentIi' />
            </p>
            <p>
              <FormattedMessage id='becomeBuilder2.contentIii' />{' '}
              <ToolTip
                link='/glossary/#officer'
                internal
                definition={<FormattedMessage id='glossaryTerms.offDef' />}
              >
                <FormattedMessage id='glossaryTerms.offTermB' />
              </ToolTip>{' '}
              <FormattedMessage id='becomeBuilder2.contentIiii' />{' '}
              <ToolTip
                link='/glossary/#director'
                internal
                definition={<FormattedMessage id='glossaryTerms.dirDef' />}
              >
                <FormattedMessage id='glossaryTerms.dirTermB' />
              </ToolTip>
              <FormattedMessage id='becomeBuilder2.contentIiv' />
            </p>
            <p>
              <FormattedMessage id='becomeBuilder2.contentIv' />
            </p>
            <p>
              <FormattedMessage id='becomeBuilder2.contentIvi' />
            </p>
            <p>
              <FormattedMessage id='becomeBuilder2.contentIvii' />
            </p>
          </Col>
        </Row>
      </Container>

      <Container>
        <Row>
          <Col md={12}>
            <SubPageBottomNav
              prevLink={{
                text: `${intl.formatMessage({ id: 'builderNav.step1' })}?`,
                path:
                  '/builder-vendor/become-a-licensed-builder/do-i-need-a-licence/'
              }}
              nextLink={{
                text: <FormattedMessage id='builderNav.step3' />,
                path:
                  '/builder-vendor/become-a-licensed-builder/applying-for-a-licence/'
              }}
            />
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

export default BuilderBeforeApplyPage
